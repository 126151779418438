<template>
  <v-container>
    <v-stepper v-model="e1" elevation="0">
      <v-stepper-header style="box-shadow: none">
        <v-stepper-step :complete="e1 > 1" step="1"> Inicio </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="e1 > 2" step="2"> Planes </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step step="3"> Tus datos </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <div v-if="loading" class="text-center">
            <v-progress-circular
              :size="100"
              color="primary"
              indeterminate
            ></v-progress-circular><br><br>
            Cargando
          </div>
          <InitForm v-else :userData="userData" @next-step="nextStep(1)" />

          <!-- <v-btn color="primary" @click="nextStep(1)"> Siguiente </v-btn> -->

          <!-- <v-btn text> Cancel </v-btn> -->
        </v-stepper-content>

        <v-stepper-content step="2">
          <Conditions/>
          <div v-if="loading" class="text-center">
            <v-progress-circular
              :size="100"
              color="primary"
              indeterminate
            ></v-progress-circular><br><br>
            Cargando
          </div>
          
          <PlanForm
            v-else
            :planData="planData"
            @next-step="nextStep"
            @back="back(2)"
          />
        </v-stepper-content>

        <v-stepper-content step="3">
          <div v-if="loading || loadingFinal" class="text-center">
            <v-progress-circular
              :size="100"
              color="primary"
              indeterminate
            ></v-progress-circular><br><br><br>
            Estamos procesando tu cotización. . .
          </div>

          <DetailForm
            v-else-if="!loading && !loadingFinal"
            :userData="userData"
            :petData="petData"
            @next-step="nextStep(3)"
            @back="back(3)"
          />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-container>
</template>

<script>
import InitForm from "@/components/InitForm.vue";
import PlanForm from "@/components/PlanForm.vue";
import DetailForm from "@/components/DetailForm.vue";
import ShieldService from "../services/shield.service";
import Conditions from '../components/Conditions.vue';

export default {
  name: "Form",
  components: {
    InitForm,
    PlanForm,
    DetailForm,
    Conditions
  },
  data() {
    return {
      e1: 1,
      loading: false,
      loadingFinal: false,
      userData: {
        name: null,
        surname: null,
        serialNumber: null,
        rut: null,
        region: {
          name: null,
        },
        commune: {
          name: null,
        },
        address: null,
        email: null,
        phoneNumber: null,

      },
      petData: {
        species: {
          name: null,
        },
        breed: null,
        petName: null,
        age: {
          label: null,
        },
        sex: {
          label: null,
        },
        hasMicrochip: null,

      },
      planData: {},
    };
  },
  methods: {
    async nextStep(step, plan) {
      this.loading = true;
      if (step === 1) {
        this.e1 = 2;
      } else if (step === 2) {
        this.planData = plan;
        var values;
        try {
          values = {
            plan: this.planData
          }
          var carro = await ShieldService.createStep(values, 2, 0, this.userData.stepId)
          this.userData.stepId = carro.data.data._id
        } catch (error) {
          console.log("Cart fail");
        }
        this.e1 = 3;
      } else if (step === 3) {
        // Conectar con SHIELD
        this.loadingFinal = true;
        try {
          const res = await ShieldService.createInsurance(
            this.userData,
            this.petData,
            this.planData,
            this.userData.stepId
          );
          // Redirigir a Flow
          window.location.href = res;
        } catch (error) {
          this.loadingFinal = false;
          this.loading = false;
          this.$alert("Ocurrió un error. Verifica que tus datos sean correctos","Error","error");
        }
      }
      this.loading = false;
    },
    back(step) {
      if (step === 2) {
        this.e1 = 1;
      } else if (step === 3) {
        this.e1 = 2;
      }
    },
  },
};
</script>